import {
  Box,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "components/loader/Loader";
import {
  ButtonComponent,
  LoadingButtonComponent,
} from "components/ui-elements";
import { useEffect, useState } from "react";
import styles from "./PDFViewDialog.module.css";
import { Document, Page, pdfjs } from "react-pdf";
import { CloseIcon } from "pages/user-management/audit-log/AuditViewDialog";
import MediaAssets from "assets";
import { DocumentExtensionTypeEnum } from "enumerations/DocumentExtensionTypeEnum";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
} from "@react-pdf-viewer/zoom";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

const CustomContainer = styled(Box)`
  /* overflow-y: auto; */
  &&& .react-pdf__message--error {
    display: none;
  }
`;

interface IPDFViewDialog {
  shouldOpen: boolean;
  title?: string;
  content: any;
  cancelHandler: (e?: any) => any;
  cancelBtnText?: string;
  isLoading?: boolean;
  handleRetry?: any;
  isDocument?: boolean; //check whether using this Dialog for Customer Document or for payment Receipts
  documentType?: string;
  filePath: string;
}

const PDFViewDialog = ({
  shouldOpen,
  title = "Customer Details",
  content,
  cancelHandler,
  cancelBtnText = "OKay",
  isLoading,
  handleRetry,
  isDocument = true,
  documentType,
  filePath,
}: IPDFViewDialog) => {
  const base64Content = content ? content : "";
  const handleClose = () => {
    cancelHandler();
  };
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    return () => {};
  }, []);

  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  const [extensionType, setExtensionType] = useState("");

  const fileType = filePath?.toLowerCase()?.split(".").pop() || "";

  const handleOnDocDownload = () => {
    const link = document.createElement("a");
    link.href =
      "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," +
      content;
    link.download = "";
    link.click();
    handleClose();
  };

  useEffect(() => {
    if (fileType) {
      switch (fileType) {
        case "pdf":
          setExtensionType(DocumentExtensionTypeEnum.PDF);
          break;
        case "doc":
        case "docx":
          setExtensionType(DocumentExtensionTypeEnum.DOCUMENT);
          break;
        case "png":
        case "jpeg":
        case "jpg":
          setExtensionType(DocumentExtensionTypeEnum.IMAGES);
          break;
        default:
          break;
      }
    }
  }, [fileType]);

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={shouldOpen}
      disableEscapeKeyDown={true}
      sx={{ textAlign: "center" }}
      BackdropProps={{ invisible: isDocument ? false : true }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          position: "sticky",
          top: 0,
          zIndex: 1000,
          borderBottom: "1px solid #c7c7cd",
          backgroundColor: "white",
        }}
      >
        <Typography fontWeight={500} variant={"h6"} component={"h6"}>
          {documentType}
        </Typography>
        <IconButton edge={"end"} component={"span"} onClick={handleClose}>
          <CloseIcon src={MediaAssets.ic_close} />
        </IconButton>
      </DialogTitle>

      {isLoading ? (
        <Loader />
      ) : content ? (
        <CustomContainer>
          <DialogContent dividers={true}>
            {content ? (
              <>
                {extensionType === DocumentExtensionTypeEnum.PDF ? (
                  // <Document file={content} error={"Failed To Load PDF"}>
                  //   {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((page) => (
                  //     <Page pageNumber={page} />
                  //   ))}
                  // </Document>
                  <>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            padding: "4px",
                          }}
                        >
                          <div style={{ padding: "0px 2px" }}>
                            <ZoomOut>
                              {(props: RenderZoomOutProps) => (
                                <button
                                  style={{
                                    backgroundColor: "#676ac0",
                                    border: "none",
                                    borderRadius: "4px",
                                    color: "#ffffff",
                                    cursor: "pointer",
                                    padding: "8px",
                                    fontSize: "0.8rem",
                                  }}
                                  onClick={props.onClick}
                                >
                                  Zoom out
                                </button>
                              )}
                            </ZoomOut>
                          </div>
                          <div style={{ padding: "0px 10px" }}>
                            <CurrentScale>
                              {(props: RenderCurrentScaleProps) => (
                                <>{`${Math.round(props.scale * 100)}%`}</>
                              )}
                            </CurrentScale>
                          </div>
                          <div style={{ padding: "0px 2px" }}>
                            <ZoomIn>
                              {(props: RenderZoomInProps) => (
                                <button
                                  style={{
                                    backgroundColor: "#676ac0",
                                    border: "none",
                                    borderRadius: "4px",
                                    color: "#ffffff",
                                    cursor: "pointer",
                                    padding: "8px",
                                  }}
                                  onClick={props.onClick}
                                >
                                  Zoom in
                                </button>
                              )}
                            </ZoomIn>
                          </div>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            overflow: "hidden",
                          }}
                        >
                          <Viewer
                            // fileUrl={`data:application/pdf;base64,${content}`}
                            fileUrl={content}
                            plugins={[zoomPluginInstance]}
                          />
                        </div>
                      </div>
                    </Worker>
                  </>
                ) : extensionType === DocumentExtensionTypeEnum.IMAGES ? (
                  <img
                    width="650"
                    height="600"
                    src={`data:image/png;base64,${content}`}
                  />
                ) : extensionType === DocumentExtensionTypeEnum.DOCUMENT ? (
                  <Box
                    sx={{
                      minHeight: "200px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Typography mb={3} mt={6}>
                      Your Browser Does Not Support This File Format
                    </Typography>
                    <LoadingButtonComponent onClick={handleOnDocDownload}>
                      Download
                    </LoadingButtonComponent>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      minHeight: "200px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Typography mb={3} mt={6}>
                      Unsupported File Format/Type
                    </Typography>
                  </Box>
                )}
              </>
            ) : null}
          </DialogContent>
        </CustomContainer>
      ) : (
        <Box className={styles["retry-button-section"]}>
          <Typography>Couldn't Load Documents, Try Again.</Typography>
          {/* <ButtonComponent
            onClick={handleRetry}
            className={styles["retry-btn"]}
          >
            Retry
          </ButtonComponent> */}
        </Box>
      )}

      <Divider />
    </Dialog>
  );
};

export default PDFViewDialog;
