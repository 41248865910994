import { Fragment } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { RouteConstants } from "./RouteConstants";
import PortalDashboard from "../pages/portal-dashboard/PortalDashboard";
import ProjectCreation from "../pages/project-management/project-creation/ProjectContentCreation";
import ProjectList from "../pages/project-management/project-list/ProjectList";
import PromiseCreation from "../pages/promise-management/promise-creation/PromiseCreation";
import PromiseList from "../pages/promise-management/promise-list/PromiseList";
import UserCreation from "../pages/user-management/user-creation/UserCreation";
import UserList from "../pages/user-management/user-list/UserList";
import UserLog from "../pages/user-management/user-log/UserLog";
import ReferralCardList from "../pages/marketing-management/referral-cards/referral-card-list/ReferralCardList";
import ReferralCardCreation from "../pages/marketing-management/referral-cards/referral-card-creation/ReferralCardCreation";
import NudgeCardList from "../pages/marketing-management/nudge-cards/nudge-card-list/NudgeCardList";
import NudgeCardCreation from "../pages/marketing-management/nudge-cards/nudge-card-creation/NudgeCardCreation";
import UpdateCreation from "../pages/marketing-management/updates/update-creation/UpdatesCreation";
import UpdateList from "../pages/marketing-management/updates/update-list/UpdateList";
import FAQList from "../pages/resource-management/faq/faq-list/FAQList";
import FAQCreation from "../pages/resource-management/faq/faq-creation/FAQCreation";
import InsightList from "../pages/resource-management/insights/insights-list/InsightList";
import InsightsCreation from "../pages/resource-management/insights/insights-creation/InsightsCreation";
import PromotionsAndOffersCreation from "../pages/marketing-management/promotions-and-offers/promotions-and-offers-creation/PromotionsAndOffersCreation";
import PromotionsAndOffersList from "../pages/marketing-management/promotions-and-offers/promotions-and-offers-list/PromotionsAndOffersList";
import TestimonialCreation from "../pages/marketing-management/testimonials/testimonial-creation/TestimonialCreation";
import TestimonialList from "../pages/marketing-management/testimonials/testimonial-list/TestimonialList";
import AuditLog from "../pages/user-management/audit-log/AuditLog";
import MediaUploadList from "../pages/project-management/media-upload-list/MediaUploadList";
import ProjectCollectionList from "../pages/project-management/project-collection-list/ProjectCollectionList";
import ProjectCollectionCreation from "../pages/project-management/project-collection-creation/ProjectCollectionCreation";
import UserManagementDashboard from "../pages/user-management/user-management-dashboard/UserManagmentDashboard";
import ProjectManagementDashboard from "../pages/project-management/project-management-dashboard/ProjectManagementDashboard";
import PromiseManagementDashboard from "../pages/promise-management/promise-management-dashboard/PromiseManagementDashboard";
import ResourceManagementDashboard from "../pages/resource-management/resource-management-dashboard/ResourceManagementDashboard";
import MarketingManagementDashboard from "../pages/marketing-management/marketing-management-dashboard/MarketingManagementDashboard";
import PageManagementDashboard from "pages/page-management/page-management-dashboard/PageManagementDashboard";
import Page1Hoabl from "pages/page-management/page-management-dashboard/page-1-hoabl/Page1Hoabl";
import Page3Promises from "pages/page-management/page-management-dashboard/page-3-promises/Page3Promises";
import Investment from "pages/page-management/page-management-dashboard/investments/Investment";
import Login from "pages/login/Login";
import { getItemFromLocalStorage } from "../utils/LocalStorageUtils";
import { Constants } from "constants/Constants";
import ProtectedRoute from "./ProtectedRoutes";
import MediaBulkUpload from "../pages/project-management/media-upload-list/bulk-upload/BulkUpload";
import { RoleTypeEnum } from "enumerations/RoleTypeEnum";
import Page5Profile from "../pages/page-management/page-management-dashboard/page-5-profile/Page5Profile";
import Page4Portfolio from "pages/page-management/page-management-dashboard/page-4-portfolio/Page4Portfolio";
import FooterTabs from "pages/page-management/page-management-dashboard/page-6-footer-tabs/FooterTabs";
import NotificationList from "pages/notification-management/notification-list/NotificationList";
import NotificationManagementDashboard from "pages/notification-management/notification-management-dashboard/NotificationManagementDashboard";
import CreateNotification from "pages/notification-management/notification-list/create-notification/CreateNotification";
import ChatSupportManagement from "pages/chat-management/ChatSupportManagement";
import HoablNewUser from "pages/chat-management/hoabl-new-user/HoablNewUser";
import HoablInvestedUser from "pages/chat-management/hoabl-invested-user/HoablInvestedUser";
import ProjectsNewUser from "pages/chat-management/projects-new-user/ProjectsNewUser";
import ProjectsInvestedUser from "pages/chat-management/projects-invested-user/ProjectsInvestedUser";
import SchedulingVC from "pages/chat-management/scheduling-vc/SchedulingVC";
import CustomerList from "pages/customer-management/customer-list/CustomerList";
import CustomerFeedback from "pages/customer-management/customer-list/customer-feedback/CustomerFeedback";
import BlockedCustomersList from "pages/customer-management/customer-list/blocked-customer-list/BlockedCustomersList";
import CustomerView from "pages/customer-management/customer-list/customer-view/CustomerView";
import CustomerInvestmentDetails from "pages/customer-management/customer-list/customer-view/customer-view-components/customer-investment-details/CustomerInvestmentDetails";
import BlogCreation from "pages/blog-management/blog-creation/BlogCreation";
import BlogManagementDashboard from "pages/blog-management/blog-management-dashboard/BlogManagementDashboard";
import LandingPageManagementDashboard from "pages/landing-page-management/landing-page-management-dashboard/LandingPageManagementDashboard";
import BlogList from "pages/blog-management/blog-listing/BlogList";
import LandingPageUrlCreation from "pages/landing-page-management/landing-page-url-creation";
import UrlList from "pages/landing-page-management/UrlList";
import PaymentFormManagement from "pages/payment-form-management/PaymentFormManagement";
import Projects from "pages/payment-form-management/projects/Projects";
import ProjectFormList from "pages/payment-form-management/form-listing/ProjectFormList";
import UpcommingProject from "pages/project-management/project-creation/upcomming-projects/UpcommingProject";
import SingleUpcommingProject from "pages/project-management/project-list/upcomming-project/SingleUpcommingProject";
import UpdateUpcommingProject from "pages/project-management/project-creation/upcomming-projects/UpdateUpcommingProject";
import LeadsList from "pages/leads-managements/LeadsList";
import MediaAndPrCreation from "pages/marketing-management/media-and-pr/MediaAndPrCreation";
import MediaAndPrList from "pages/marketing-management/media-and-pr/MediaAndPrList";
import AppUpdates from "pages/notification-management/app-updates/AppUpdates";
import CreateAppUpdate from "pages/notification-management/app-updates/CreateAppUpdate";
// import PaymentHistoryListing from "pages/payment-history/PaymentHistoryListing/ProjectFormList";
// import PaymentHistory from "pages/payment-history/PaymentHistory";

// const actionDispatch = (dispatch: any) => {
//   return {
//     setUsersState: (key: any, data: any) =>
//       dispatch(UserActions.setUsersState(key, data)),
//   };
// };

const AppRoutes = () => {
  let routes = (
    <Fragment>
      <Route path="/" element={<Login />} />
      <Route path={RouteConstants.login} element={<Login />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route path={RouteConstants.resetPassword} element={<Login />} />
    </Fragment>
  );

  const token = getItemFromLocalStorage(Constants.authorizationToken);
  const getInitialRouteAfterLogin = () => {
    const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);
    if (
      loggedInUser &&
      loggedInUser.admin &&
      loggedInUser.admin.roleId === RoleTypeEnum.Admin
    ) {
      const sidemenu = loggedInUser?.sideMenuList
        ? loggedInUser?.sideMenuList
        : [];
      return sidemenu[0].route;
    } else {
      return RouteConstants.userManagementDashboard;
    }
  };

  if (token) {
    routes = (
      <Route element={<ProtectedRoute />}>
        {/*===============================================
        ==========Portal Dashboard Routes=============
        ==================================================*/}
        <Route
          path={RouteConstants.portalDashboard}
          element={<PortalDashboard />}
        />
        {/*===============================================
        ==========User Management Routes=============
        ==================================================*/}
        <Route
          path={RouteConstants.userManagementDashboard}
          element={<UserManagementDashboard />}
        />
        <Route path={RouteConstants.userList} element={<UserList />} />
        <Route path={RouteConstants.userCreation} element={<UserCreation />} />
        <Route path={RouteConstants.updateUser} element={<UserCreation />} />
        <Route path={RouteConstants.userLog} element={<UserLog />} />
        <Route path={RouteConstants.auditLog} element={<AuditLog />} />
        {/*===============================================
        ==========Project Management Routes=============
        ==================================================*/}
        <Route
          path={RouteConstants.projectManagementDashboard}
          element={<ProjectManagementDashboard />}
        />
        <Route
          path={`${RouteConstants.projectCreation}/*`}
          element={<ProjectCreation />}
        />
        <Route path={RouteConstants.projectList} element={<ProjectList />} />
        <Route
          path={RouteConstants.mediaUploadList}
          element={<MediaUploadList />}
        />
        <Route
          path={RouteConstants.mediaBulkUpload}
          element={<MediaBulkUpload />}
        />
        <Route
          path={RouteConstants.projectCollections}
          element={<ProjectCollectionList />}
        />
        <Route
          path={RouteConstants.projectCollectionCreation}
          element={<ProjectCollectionCreation />}
        />
        {/*===============================================
        ==========Page Management Routes=============
        ==================================================*/}
        <Route
          path={RouteConstants.pageManagementDashboard}
          element={<PageManagementDashboard />}
        />
        <Route path={RouteConstants.page1Hoabl} element={<Page1Hoabl />} />
        <Route
          path={RouteConstants.page2Investments}
          element={<Investment />}
        />
        <Route
          path={RouteConstants.page3Promises}
          element={<Page3Promises />}
        />
        <Route
          path={RouteConstants.page4Portfolio}
          element={<Page4Portfolio />}
        />
        <Route path={RouteConstants.page5Profile} element={<Page5Profile />} />
        <Route path={RouteConstants.page6FooterTabs} element={<FooterTabs />} />
        {/*===============================================
        ==========Promise Management Routes=============
        ==================================================*/}
        <Route
          path={RouteConstants.promiseManagementDashboard}
          element={<PromiseManagementDashboard />}
        />
        <Route
          path={RouteConstants.promiseCreation}
          element={<PromiseCreation />}
        />
        <Route path={RouteConstants.promiseList} element={<PromiseList />} />
        {/*===============================================
        ==========Resource Management Routes=============
        ==================================================*/}
        <Route
          path={RouteConstants.resourceManagementDashboard}
          element={<ResourceManagementDashboard />}
        />
        <Route path={`${RouteConstants.faqList}`} element={<FAQList />} />
        <Route
          path={`${RouteConstants.faqCreation}`}
          element={<FAQCreation />}
        />
        <Route path={RouteConstants.insightList} element={<InsightList />} />
        <Route
          path={RouteConstants.insightCreation}
          element={<InsightsCreation />}
        />
        <Route path={RouteConstants.faqList} element={<FAQList />} />
        {/*===============================================
        ==========Marketing Management Routes=============
        ==================================================*/}
        <Route
          path={RouteConstants.marketingManagementDashboard}
          element={<MarketingManagementDashboard />}
        />
        <Route
          path={RouteConstants.promotionsAndOffersList}
          element={<PromotionsAndOffersList />}
        />
        <Route
          path={RouteConstants.promotionAndOffersCreation}
          element={<PromotionsAndOffersCreation />}
        />
        <Route
          path={RouteConstants.nudgeCardList}
          element={<NudgeCardList />}
        />
        <Route
          path={RouteConstants.nudgeCardCreation}
          element={<NudgeCardCreation />}
        />
        <Route
          path={RouteConstants.referralCardList}
          element={<ReferralCardList />}
        />
        <Route
          path={RouteConstants.referralCardCreation}
          element={<ReferralCardCreation />}
        />
        <Route path={RouteConstants.updateList} element={<UpdateList />} />
        <Route
          path={RouteConstants.updateCreation}
          element={<UpdateCreation />}
        />
        <Route
          path={RouteConstants.testimonialsList}
          element={<TestimonialList />}
        />
        <Route
          path={RouteConstants.testimonialCreation}
          element={<TestimonialCreation />}
        />
        <Route
          path={RouteConstants.mediaAndPrList}
          element={<MediaAndPrList />}
        />
        <Route
          path={RouteConstants.mediaAndPrCreation}
          element={<MediaAndPrCreation />}
        />
        {/*===============================================
        ==========Notification Management Routes=============
      ==================================================*/}
        <Route
          path={RouteConstants.notificationManagementDashboard}
          element={<NotificationManagementDashboard />}
        />
        <Route
          path={RouteConstants.notificationList}
          element={<NotificationList />}
        />
        <Route
          path={RouteConstants.notificationCreation}
          element={<CreateNotification />}
        />
        {/* <Route path={RouteConstants.appUpdates} element={<AppUpdates />} />
        <Route
          path={RouteConstants.appUpdateCreation}
          element={<CreateAppUpdate />}
        /> */}
        {/*===============================================
        ================Initial Routes===================
        ==================================================*/}
        <Route
          path="/"
          element={<Navigate to={getInitialRouteAfterLogin()} />}
        />
        <Route
          path="*"
          element={<Navigate to={getInitialRouteAfterLogin()} />}
        />
        {/*===============================================
        ================Chat Management Routes===================
        ==================================================*/}
        <Route
          path={RouteConstants.chatSupportManagementDashboard}
          element={<ChatSupportManagement />}
        />
        <Route path={RouteConstants.hoablNewUser} element={<HoablNewUser />} />
        <Route
          path={RouteConstants.projectsNewUser}
          element={<ProjectsNewUser />}
        />
        <Route
          path={RouteConstants.hoablInvestedUser}
          element={<HoablInvestedUser />}
        />
        <Route
          path={RouteConstants.projectsInvestedUser}
          element={<ProjectsInvestedUser />}
        />
        <Route path={RouteConstants.schedulingVC} element={<SchedulingVC />} />
        {/*===============================================
        ================Customer Management Routes===================
        ==================================================*/}
        <Route
          path={RouteConstants.customerManagementDashboard}
          element={<CustomerList />}
        />
        <Route path={RouteConstants.customerList} element={<CustomerList />} />
        <Route
          path={RouteConstants.customerFeedback}
          element={<CustomerFeedback />}
        />
        <Route
          path={RouteConstants.blockedCustomersList}
          element={<BlockedCustomersList />}
        />
        <Route path={RouteConstants.customerView} element={<CustomerView />} />
        <Route
          path={RouteConstants.customerInvestmentDetails}
          element={<CustomerInvestmentDetails />}
        />
        <Route
          path={RouteConstants.blogManagementDashboard}
          element={<BlogManagementDashboard />}
        />
        <Route path={RouteConstants.blogCreation} element={<BlogCreation />} />
        <Route path={RouteConstants.blogs} element={<BlogList />} />
        <Route
          path={RouteConstants.landingPageManagementDashboard}
          element={<LandingPageManagementDashboard />}
        />
        <Route path={RouteConstants.urlList} element={<UrlList />} />
        <Route
          path={RouteConstants.landingPageUrlCreation}
          element={<LandingPageUrlCreation />}
        />

        {/*===============================================
        ================Chat Management Routes===================
        ==================================================*/}
        {/* <Route
          path={RouteConstants.paymentFormManagement}
          element={<PaymentFormManagement />}
        />
        <Route path={`${RouteConstants.projects}/*`} element={<Projects />} /> */}
        <Route
          path={RouteConstants.paymentFormManagement}
          element={<PaymentFormManagement />}
        >
          <Route index element={<ProjectFormList />} />
          <Route
            path={RouteConstants.formLists}
            element={<ProjectFormList />}
          />
          <Route path={RouteConstants.projects} element={<Projects />} />
        </Route>
        <Route
          path={RouteConstants.upCommingProject}
          element={<UpcommingProject />}
        />
        <Route
          path={RouteConstants.singleUpcommingProject}
          element={<SingleUpcommingProject />}
        />
        <Route
          path={RouteConstants.upCommingProjectUpdate}
          element={<UpdateUpcommingProject />}
        />
        <Route path={RouteConstants.leadsList} element={<LeadsList />} />



        {/* TODO: Commented because not tested on UAT yet and need to release  */}
        {/* ################## PAYMENT HISTORY ROUTES ###################### 
        <Route
          path={RouteConstants.paymentHistory}
          element={<PaymentHistory />}
        >
          <Route index element={<PaymentHistoryListing />} />
          
        </Route> */}

      </Route>
    );
  }

  return (
    <div>
      <Routes>{routes}</Routes>
    </div>
  );
};

export default AppRoutes;
