import React, { Fragment } from "react";
import {
  Avatar,
  Container,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import styles from "./OtherSectionHeadings.module.css";
import {
  LoadingButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { getIn, useFormikContext } from "formik";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { sectionHeadingConstansts } from "./OtherSectionHeadingsConstants";
import { useSelector } from "react-redux";

const OtherSectionHeadings = () => {
  const {
    values,
    handleChange,
    // handleSubmit,
    // setFieldValue,
    // resetForm,
    handleBlur,
    errors,
    touched,
    // isValid,
  } = useFormikContext<IInitinalValueProjectContent>();
  const isViewMode = useSelector((state: any) => (
    state?.projectContentManagement?.isViewMode
 ));
 console.log(errors,'..');
 
  return (
    <Fragment>
      <Container>
        <div>
          <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
            <Typography color={"#000"} variant={"h6"} component={"h6"}>
              Promises
            </Typography>
            <Tooltip arrow title={"Promises Section Heading"} placement="top">
              <Avatar
                className={styles["info-icon"]}
                src={MediaAssets.ic_info}
                variant="rounded"
              />
            </Tooltip>
          </Stack>

          <Grid container mb={4}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextFieldComponent
                required
                label={"Section Heading"}
                type="text"
                placeholder="Enter"
                name={"otherSectionHeadings.promises.sectionHeading"}
                inputProps={{
                  maxLength: sectionHeadingConstansts?.sectionCharacterLimit,
                }}
                onBlur={handleBlur}
                value={values?.otherSectionHeadings?.promises?.sectionHeading}
                onChange={handleChange}
                fieldhelpertext={
                  getIn(
                    touched,
                    `otherSectionHeadings.promises.sectionHeading`
                  ) &&
                  getIn(errors, `otherSectionHeadings.promises.sectionHeading`)
                }
                error={Boolean(
                  getIn(
                    errors,
                    `otherSectionHeadings.promises.sectionHeading`
                  ) &&
                    getIn(
                      touched,
                      `otherSectionHeadings.promises.sectionHeading`
                    )
                )}
                disabled={isViewMode}

              />
            </Grid>
          </Grid>

          {/* <Divider className={styles["content-divider"]} />

              <Stack justifyContent={"end"} margin={"20px 0"}>
                <LoadingButtonComponent
                >
                  Save
                </LoadingButtonComponent>
              </Stack> */}
        </div>


        <div>
          <Stack justifyContent="start" spacing={2} mt={3} mb={3}>
            <Typography color={"#000"} variant={"h6"} component={"h6"}>
              Testimonials
            </Typography>
            <Tooltip
              arrow
              title={"Testimonials Section Heading"}
              placement="top"
            >
              <Avatar
                className={styles["info-icon"]}
                src={MediaAssets.ic_info}
                variant="rounded"
              />
            </Tooltip>
          </Stack>

          <Grid container mb={4}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextFieldComponent
                required
                label={"Section Heading"}
                type="text"
                placeholder="Enter"
                name={"otherSectionHeadings.testimonials.sectionHeading"}
                inputProps={{
                  maxLength: sectionHeadingConstansts?.sectionCharacterLimit,
                }}
                onBlur={handleBlur}
                value={
                  values?.otherSectionHeadings?.testimonials?.sectionHeading
                }
                onChange={handleChange}
                fieldhelpertext={
                  getIn(
                    touched,
                    `otherSectionHeadings.testimonials.sectionHeading`
                  ) &&
                  getIn(
                    errors,
                    `otherSectionHeadings.testimonials.sectionHeading`
                  )
                }
                error={Boolean(
                  getIn(
                    errors,
                    `otherSectionHeadings.testimonials.sectionHeading`
                  ) &&
                    getIn(
                      touched,
                      `otherSectionHeadings.testimonials.sectionHeading`
                    )
                )}
                disabled={isViewMode}

              />
            </Grid>
          </Grid>

          {/* <Divider className={styles["content-divider"]} />

              <Stack justifyContent={"end"} margin={"20px 0"}>
                <LoadingButtonComponent
                >
                  Save
                </LoadingButtonComponent>
              </Stack> */}
        </div>

        {/* <Divider className={styles["content-divider"]} /> */}
      </Container>
    </Fragment>
  );
};

export default OtherSectionHeadings;
