import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import MediaAssets from "assets";
import ButtonComponent from "../button/ButtonComponent";
import { MediaCardsStyleWrapper } from "./MediaCards.style";
import { formatDateTime } from "utils/DateFormatterUtils";
import { Constants } from "constants/Constants";

interface IMediaCardsProps {
  handleViewClick?: (
    path: string | null,
    documentType: string | undefined
  ) => void;
  mediaName?: string | null;
  path?: string | null;
  mediaUploadDate?: Date | null;
  documentType?: string | undefined;
}

const MediaCards = ({
  handleViewClick,
  mediaName = "Document",
  path = "",
  mediaUploadDate,
  documentType,
}: IMediaCardsProps) => {
  return (
    <MediaCardsStyleWrapper isMediaUploaded={mediaName}>
      {mediaName ? (
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem
            alignItems="flex-start"
            secondaryAction={
              <ButtonComponent
                sx={{ textDecoration: "underline", marginTop: "0.5rem" }}
                variant="text"
                color="inherit"
                onClick={() =>
                  handleViewClick && handleViewClick(path, documentType)
                }
              >
                View
              </ButtonComponent>
            }
          >
            <ListItemAvatar>
              <Avatar
                alt="Customer profile pic"
                src={MediaAssets.ic_google_docs}
                variant="rounded"
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Tooltip title={mediaName} placement="top">
                  <Typography>
                    {mediaName?.length > 7
                      ? `${mediaName?.slice(0, 7)}...`
                      : mediaName}
                  </Typography>
                </Tooltip>
              }
              secondary={
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="caption"
                  color="text.primary"
                >
                  {mediaUploadDate
                    ? formatDateTime(
                        mediaUploadDate,
                        Constants.dateFormatDDmmYYYY
                      )
                    : null}
                </Typography>
              }
            />
          </ListItem>
        </List>
      ) : (
        <Typography color={"grey.400"}>File not uploaded</Typography>
      )}
    </MediaCardsStyleWrapper>
  );
};

export default MediaCards;
