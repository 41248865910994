import { useFormikContext } from "formik";
import { IBlogsInitialValues } from "./blogs-constants/BlogsInitialValues";
import React, { Fragment, useEffect, useState } from "react";
import { Avatar, Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { SwitchComponent } from "../../../components/ui-elements";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import MediaAssets from "assets";
import styles from "./BlogCreation.module.css";
import ImageUploadDragDrop from "./ImageUploader/ImageUploader";
import QuillEditor from "components/quill-editor/QuillEditor";
import {
  DescriptionTypeEnum,
  blogCreationConstants,
} from "./BlogCreationConstants";
import UpdateCreationAction from "redux-container/marketing-management/updates/UpdateCreationRedux";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../components/confirmation-dialog/ConfirmationDialog";
import Loader from "../../../components/loader/Loader";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import { DragAndDropStyle } from "./ImageUploader/ImageUploader";

const actionDispatch = (dispatch: any) => {
  return {
    addMediaUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    deleteFile: (data: any) =>
      dispatch(FileUploadActions.fileDeleteRequest(data)),
    fileUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    setUpdatesState: (key: any, value: any) =>
      dispatch(UpdateCreationAction?.setUpdatesState(key, value)),
  };
};

const BlogCreationDetailedInfo = (props: any) => {
  const { isDisabled, setMediaToggle, mediaToggle } = props;
  const { addMediaUpload, fileUpload, setUpdatesState } = actionDispatch(
    useDispatch()
  );
  const location: any = useLocation();
  const { values, handleChange, setFieldValue, touched, errors, handleBlur } =
    useFormikContext<IBlogsInitialValues>();
  const isEditMode = location?.state?.isEditMode
    ? location?.state?.isEditMode
    : false;

  const [callingUpload, setCallingUpload] = useState(0);
  const assignEmpty: string = "";

  const [numberOfFieldsToShow, setNumberOfFieldToShow] = useState<number>(1);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const handleMediaToggleChange = () => {
    setMediaToggle(!mediaToggle);
  };

  const { isMediaLoading, fileUploadDetails, mediaDeleteIndex } = useSelector(
    (state: any) => ({
      isMediaLoading: state?.fileUpload?.isLoading,
      fileUploadDetails: state.fileUpload.imageDetails,
      mediaDeleteIndex: state.marketingUpdates?.mediaDeleteIndex,
    })
  );

  useEffect(() => {
    if (values?.formType === DescriptionTypeEnum.DetailedDescription) {
      setNumberOfFieldToShow(3);
    } else {
      setNumberOfFieldToShow(1);
    }
  }, [values?.formType]);

  const handleuploadImage = (file: any, setCount: any, setFieldValue?: any) => {
    setCallingUpload(setCount);

    let imageUrl;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
      };
      reader.readAsArrayBuffer(file);
      fileUpload(data);
      addMediaUpload(data);
    } else {
      imageUrl = null;
    }
  };
  const handleFileDelete = (deleteIndex: any) => {
    setUpdatesState("mediaDeleteIndex", deleteIndex);
    setOpenConfirmationDialog(true);
  };

  const tootlTipText = () => {
    if (values?.formType === DescriptionTypeEnum.BriefDescription) {
      return "This description will not have a detailed page on Customer App. The Character is 130";
    } else {
      return "This description will have character limit of 3000";
    }
  };

  useEffect(() => {
    switch (callingUpload) {
      case 1:
        return setFieldValue(
          `detailedInfo[0].media.value.url`,
          fileUploadDetails?.url
        );
      case 2:
        return setFieldValue(
          `detailedInfo[1].media.value.url`,
          fileUploadDetails?.url
        );
      case 3:
        return setFieldValue(
          `detailedInfo[2].media.value.url`,
          fileUploadDetails?.url
        );
      default:
        break;
    }
  }, [callingUpload, fileUploadDetails?.url]);

  const handleTextEditor = (
    name: string,
    newValue: string,
    setFieldValue: any
  ) => {
    setFieldValue(name, newValue);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleOkConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    switch (mediaDeleteIndex) {
      case 1:
        return setFieldValue("detailedInfo[0].media.value.url", "");
      case 2:
        return setFieldValue("detailedInfo[1].media.value.url", "");
      case 3:
        return setFieldValue("detailedInfo[2].media.value.url", "");
      default:
        break;
    }
  };

  return (
    <Fragment>
      {values?.formType === DescriptionTypeEnum.DetailedDescription && (
        <Typography
          className={`${styles["detailed-text"]} margin-bottom-20px`}
          variant={"body1"}
        >
          Detailed Information
        </Typography>
      )}
      <div>
        {values?.detailedInfo?.map((updateItem: any, updateIndex: number) => {         
          if (updateIndex + 1 <= numberOfFieldsToShow) {
            return (
              <Box>
                <ConfirmationDialog
                  shouldOpen={openConfirmationDialog}
                  title="Delete Media Image?"
                  content="This action will Delete Media Image data. Are you sure you want to continue?"
                  okText="Delete"
                  cancelHandler={handleCloseConfirmationDialog}
                  okHandler={handleOkConfirmationDialog}
                />
                <Grid container spacing={4}>
                  <Grid item xs={4} md={4} lg={4} sm={4} xl={4}>
                    <Stack
                      className={styles["media-section"]}
                      justifyContent={"flex-start"}
                      spacing={2}
                    >
                      <Typography variant={"body1"}>Upload Media</Typography>
                      {values?.formType ===
                        DescriptionTypeEnum.BriefDescription && (
                        <>
                          <SwitchComponent
                            id={"1"}
                            disabled={isDisabled}
                            value={mediaToggle}
                            checked={mediaToggle}
                            onChange={handleMediaToggleChange}
                          />
                        </>
                      )}
                    </Stack>
                    <>
                      {callingUpload === updateIndex + 1 &&
                      isMediaLoading === true ? (
                        <DragAndDropStyle>
                          <Loader />
                        </DragAndDropStyle>
                      ) : (
                        <Fragment>
                          {isDisabled ? (
                            <Box
                              mb={4}
                              sx={{
                                border: "1px solid rgb(229, 229, 232)",
                                width: "269px",
                                height: "250px",
                              }}
                            >
                              <img
                                className={styles["preview-image-section"]}
                                src={
                                  values?.detailedInfo[updateIndex]?.media
                                    ?.value?.url
                                }
                                alt={`Media Upload ${updateIndex + 1}`}
                              />
                            </Box>
                          ) : (
                            <ImageUploadDragDrop
                              isDisabled={isDisabled}
                              name={`detailedInfo[${updateIndex}]?.media?.value?.url`}
                              onDrop={handleChange}
                              value={
                                values?.detailedInfo[updateIndex]?.media?.value
                                  ?.url
                                  ? values?.detailedInfo[updateIndex]?.media
                                      ?.value?.url
                                  : assignEmpty
                              }
                              previewimage={
                                values?.detailedInfo[updateIndex]?.media?.value
                                  ?.url
                                  ? values?.detailedInfo[updateIndex]?.media
                                      ?.value?.url
                                  : assignEmpty
                              }
                              onChange={(file: any) => {
                                handleuploadImage(
                                  file,
                                  updateIndex + 1,
                                  setFieldValue
                                );
                              }}
                              cancelimage={() =>
                                handleFileDelete(updateIndex + 1)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </Fragment>
                      )}
                    </>
                  </Grid>

                  <Grid item xs={8} lg={8} sm={8} xl={8} md={8}>
                    <Stack
                      className={styles["media-section"]}
                      justifyContent={"flex-start"}
                      spacing={2}
                    >
                      <Typography variant={"body1"}>Description</Typography>
                      <Tooltip arrow title={tootlTipText()} placement="top">
                        <Avatar
                          className={styles["info-icon"]}
                          src={MediaAssets.ic_info}
                          variant="rounded"
                        />
                      </Tooltip>
                    </Stack>
                    <QuillEditor
                      placeholder="Enter description"
                      className={styles["quill-editor"]}
                      name={`detailedInfo[${updateIndex}].description`}
                      onBlur={handleBlur}
                      onChange={(name: string, newValue: string) => {
                        handleTextEditor(name, newValue, setFieldValue);
                      }}
                      value={values?.detailedInfo[updateIndex]?.description}
                      style={{ height: "200px" }}
                      disabled={isDisabled}
                      inputProps={
                        !isDisabled &&
                        values?.formType ===
                          DescriptionTypeEnum.BriefDescription
                          ? {
                              maxLength: values?.detailedInfo[0]?.media?.value
                                ?.url
                                ? blogCreationConstants.briefDescriptionCharacterLimitWithImage
                                : blogCreationConstants.briefDescriptionCharacterLimit,
                            }
                          : DescriptionTypeEnum.DetailedDescription
                          ? {
                              maxLength:
                                blogCreationConstants.detailedDescriptionCharacterLimit,
                            }
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                {updateIndex + 1 < numberOfFieldsToShow && (
                  <Divider className={`${styles["divider"]}`} />
                )}
              </Box>
            );
          }
        })}
      </div>
    </Fragment>
  );
};

export default BlogCreationDetailedInfo;
